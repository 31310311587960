body {
  font-family: 'Roboto', sans-serif;
}

.list-s {
  list-style-type: none;
  padding-left: 20px;
  margin: 0;
}
.list-s li {
  font-weight: 500;
  position: relative;
  line-height: 30px;
}
.list-s li::before {
  content: "";
  display: block;
  position: absolute;
  top: 10px;
  left: -20px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #3871c2;
}

.list {
  list-style-type: none;
  padding-left: 25px;
  margin: 0;
}
.list li {
  font-size: 20px;
  font-weight: 500;
  position: relative;
  line-height: 35px;
}
.list li::before {
  content: "";
  display: block;
  position: absolute;
  top: 11px;
  left: -25px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #3871c2;
}

.invoice-download {
  display: block;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.invoice-download img {
  position: relative;
  transition: height 0.2s;
}
.invoice-download:hover img {
  height: 28px;
}

.card-header {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.card-footer {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.card-header, .card-footer {
  background-color: rgb(225 239 255 / 30%);
}

.link {
  color: black;
  text-decoration: none;
}
.link:hover {
  cursor: pointer;
  text-decoration: underline;
}

.pointer {
  cursor: pointer;
}

.border-right {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.card-link {
  cursor: pointer;
  transition: box-shadow 200ms ease!important;
}
.card-link:hover {
  -webkit-box-shadow: 0px 0px 28px -19px rgba(132, 132, 132, 1);
-moz-box-shadow: 0px 0px 28px -19px rgba(132, 132, 132, 1);
box-shadow: 0px 0px 28px -19px rgba(132, 132, 132, 1);
}
